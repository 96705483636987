/**
  * Name: Rockie | Crypto Exchange HTML Template
  * Version: 1.0.1
  * Author: Themesflat
  * Author URI: http://www.themesflat.com
*/
@use "./scss/" as *;
@use "./scss/components/" as *;

// @import "../bootstrap/css/bootstrap.min.css";
// @import "../../assets/style.css";
// @import "../dist/aos.css";

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css");

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary: #3772ff;
  --bg: #fff;
  --bg1: #fff;
  --onsurface: #23262f;
  --surface: #f7f9ff;
  --line: #e5e5e5;
  --text: #777e90;
  --second: #58bd7d;
}
.is_dark {
  --bg: #141416;
  --bg1: #222630;
  --onsurface: #fff;
  --surface: #18191d;
  --line: #23262f;
  --text: #b1b5c3;
}
body {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  position: relative;
  overflow-x: hidden;
  color: var(--text);
  background: var(--bg);
  min-height: 100vh;
}
button {
  font-family: "DM Sans", sans-serif;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.blog_content > div > ul,
.blog_content > div > ul > li {
  list-style-type: unset !important;
}

.blog_content > div > ol,
.blog_content > div > ol > li {
  list-style: decimal !important;
}
p {
  margin: 0;
  padding: 0;
  letter-spacing: -0.2px;
}
a {
  text-decoration: none;
  color: unset;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  color: var(--onsurface);
  &:hover {
    color: var(--primary);
  }
}

button {
  color: var(--onsurface);
  transition: all 0.3s ease-in-out;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  color: var(--onsurface);
}

h2,
.h2 {
  font-size: 64px;
}
h3,
.h3 {
  font-size: 48px;
  letter-spacing: -1px;

  @include mobile {
    font-size: 32px;
  }
}
h4,
.h4 {
  font-size: 40px;
}
h5,
.h5 {
  font-size: 36px;
}
h6,
.h6 {
  font-size: 24px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-21 {
  font-size: 21px;
}
.fs-24 {
  font-size: 24px;
}
.fs-30 {
  font-size: 30px;
}
.fs-36 {
  font-size: 36px;
}
.fs-42 {
  font-size: 42px;
}
.mr-0 {
  margin-right: 0;
}
.pt-128 {
  padding-top: 128px;
}
.mt--16 {
  margin-top: -16px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-17 {
  margin-top: 17px;
}
.mt--23 {
  margin-top: -23px;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-9 {
  margin-top: 9px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-16 {
  margin-top: 16px !important;
}
.mt-19 {
  margin-top: 19px;
}
.mt-21 {
  margin-top: 21px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-27 {
  margin-top: 27px;
}
.mt-28 {
  margin-top: 28px;
}
.mt-29 {
  margin-top: 29px;
}
.mt-37 {
  margin-top: 37px;
}
.mt-42 {
  margin-top: 42px;
}
.mt-48 {
  margin-top: 48px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-51 {
  margin-top: 51px;
}
.mt-52 {
  margin-top: 52px;
}
.mt-53 {
  margin-top: 53px;
}
.mt-61 {
  margin-top: 61px;
}
.mt-67 {
  margin-top: 67px;
}
.mt-104 {
  margin-top: 104px;
}
.mt-107 {
  margin-top: 107px;
}
.mt-200 {
  margin-top: 200px;
}
.mt-223 {
  margin-top: 223px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-6 {
  margin-bottom: 6px !important;
}
.mb-7 {
  margin-bottom: 7px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-13 {
  margin-bottom: 13px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-26 {
  margin-bottom: 26px;
}
.mb-28 {
  margin-bottom: 28px;
}
.mb-29 {
  margin-bottom: 29px;
}
.mb-31 {
  margin-bottom: 31px;
}
.mb-33 {
  margin-bottom: 33px;
}
.mb-37 {
  margin-bottom: 37px;
}
.mb-41 {
  margin-bottom: 41px;
}
.mb-43 {
  margin-bottom: 43px;
}
.mb-46 {
  margin-bottom: 46px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-53 {
  margin-bottom: 53px;
}
.mb-58 {
  margin-bottom: 58px;
}
.mr-28 {
  margin-right: 28px;
}
.pt-12 {
  padding-top: 12px;
}
.pt-24 {
  padding-top: 24px;
}
.container {
  max-width: 1440px;
  width: 100%;
  margin: auto;
}

.row > * {
  padding-left: 15px;
  padding-right: 15px;
}

.center {
  text-align: center;
}
.right {
  text-align: right;
}

.bg {
  background: var(--surface);
}
.interactive {
  background: #2e72d2;
}
.critical {
  background: #d33535;
}
.color-critical {
  color: #d33535;
}
.warning {
  background: #fabe3c;
}
.success {
  background: #58bd7d;
}
.color-success {
  color: #58bd7d;
}
.color-white {
  color: #fff;
}
.hightlight {
  background: #1e58c8;
}
.decorative {
  background: linear-gradient(216.56deg, #383fde 5.32%, #8444d5 94.32%);
}

@keyframes move5 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.mouse-cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
}
.cursor-inner {
  margin-left: -3px;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  z-index: 100;
  background-color: var(--primary-color);
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.cursor-inner.cursor-hover {
  margin-left: -20px;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  background-color: var(--primary-color);
  opacity: 0.3;
}
.cursor-outer {
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  border: 2px solid var(--primary-color);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 100;
  opacity: 0.5;
  -webkit-transition: all 0.08s ease-out;
  transition: all 0.08s ease-out;
}
.cursor-outer.cursor-hover {
  opacity: 0;
}
body[data-magic-cursor="hide"] .mouse-cursor {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1111;
}

.d-flex {
  display: flex;
}
.left {
  text-align: left;
}

p > img {
  width: 100%;
}

.tags > .ul {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;

  margin-top: 3vh;
}

.tags > .ul > li {
  border-radius: 100px;
}

.tags > .ul > li > a {
  color: var(--text);
}

.sidebar .widget.recent {
  margin-top: 28px;
}
.sidebar .widget.recent ul li a {
  display: flex;
  margin-bottom: 10px;
}
.sidebar .widget.recent ul li a .image {
  min-width: 80px;
  width: 80px;
  height: 80px;
  margin-right: 13px;
  border-radius: 8px;
  overflow: hidden;
}
.sidebar .widget.recent ul li a .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sidebar .widget.recent ul li a .content .category {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--text);
  margin-bottom: 8px;
}
.sidebar .widget.recent ul li a .content .title {
  display: block;
  text-transform: capitalize;
  color: var(--onsurface);
}

.comments {
  margin-top: 10vh;
}

.comments_content {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.comments_content > .img > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.markdown > * {
  all: revert;
}

.filter_search_blog {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.details_grid {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  gap: 10px;
}
.details_grid > div:last-child {
  background-color: unset !important;
}
.details_grid > div {
  background-color: #f0f2f8;
  border-radius: 5px;
  padding: 5px;
  padding-left: 15px;
}
.rating {
  border-radius: 20px;
  padding: 3px;
  text-align: center;
  color: white;
  width: 70px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
}

.icon_col {
  width: 30px;
  height: 30px;
  background-color: white;
  color: #3772ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.red {
  background-color: #d33535;
}

.green {
  background-color: #58bd7d;
}
.blue {
  background-color: #3772ff;
}
.yellow {
  background-color: #e2c008;
}

.select_filters {
  display: flex;
  gap: 10px;
}
.loader_cont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.711);
  z-index: 99900000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 62px;
  height: 62px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.blog_content > div {
  background: transparent !important;
}

.coin_star {
  position: sticky;

  transition: 0.3s;
}

.coin_star:hover {
  cursor: pointer;
  opacity: 0.9;
  color: rgb(55, 114, 255) !important;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.react-select__control,
.react-select__menu {
  background-color: transparent !important;
}

.react-select__single-value {
  color: #b1b5c3 !important;
}

.coins_tbody > tr > td {
  font-weight: 300 !important;
  font-size: 16px !important;
}

.table_sp > tbody > tr > td {
  padding: 10px;
  color: black;
}
.coin_socials {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.coin_socials > a > i {
  font-size: 23px;
}

.tokens_info {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.tokens_info > div:nth-child(2) {
  font-weight: 500;
  color: black;
}
.contract_scroll {
  transition: 0.3s;
}
.contract_scroll:hover {
  opacity: 0.7;
  cursor: pointer;
}

.coins_link:hover {
  color: initial !important;
}
.coin_table_tr {
  border-radius: 8px;
  transition: 0.3s;
}
.coin_table_tr:hover {
  cursor: pointer;
  background: rgba(55, 114, 255, 0.1);
}

.search_input:focus,
.search_input:focus-visible {
  border: none !important;
  outline: none;
}

.btn_disabled {
  padding: 13px 30px;
  background: #575a61;
  border-radius: 90px;
  color: #fff;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.btn_disabled:hover {
  cursor: not-allowed !important;
}

.search_list {
  background-color: white;
  width: 100%;
  position: absolute;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 100000;
  padding: 10px;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.search_result:hover {
  background-color: rgb(55 114 255 / 9%);
}

.popup_cont {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.558);
}

.popup {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  width: 30vw;
}
.search_input {
  width: 100%;
  background-color: var(--surface);
}

.dropdown-item:active {
  background-color: unset !important;
}

.search_dark {
  background-color: #222630 !important;
  border-color: #222630 !important;
  border-radius: 10px;
}
.search_dark > a:hover {
  background-color: #404040 !important;
}
.search_icon_dark {
  color: white !important;
}

.search_list {
  max-height: 80vh;
  overflow-y: auto;
}

.sp_hover:hover {
  background-color: unset !important;
}

.menu_dark {
  background-color: #141416 !important;
}

.coin-list
  .coin-list__main
  .react-tabs
  .react-tabs__tab-panel
  .content-inner
  tbody
  tr {
  border-bottom-width: 3px !important;
}
.no_box_shadow {
  box-shadow: unset !important;
}

.markets .coin-list .flat-tabs1 .menu-tab1 li h6 {
  white-space: nowrap;
}

.markets .coin-list .flat-tabs1 .menu-tab1 {
  overflow: auto;
}
.btn-action:hover {
  cursor: pointer;
}
.home-2 .crypto .crypto__main .crypto-box .left h6 {
  font-size: 18px;
}
.is_light .crypto .crypto__main {
  border: 1px solid rgba(128, 128, 128, 0.15);
  // background: rgb(239, 239, 240);
}

.is_dark .pagination > .page-item > a {
  background-color: #222630 !important;
  border-color: #b1b5c30e;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.section__space {
  padding: 120px 0px;
}

.terms {
  margin-top: 52px;
}

.terms_cont {
  width: 80% !important;
}
.terms-area {
  hr {
    margin: 60px 0px;
  }
}

.terms__single {
  h3 {
    margin-bottom: 30px;
  }
  h4 {
    margin-top: 30px;
  }
  p {
    margin-top: 25px;
    font-size: 20px;
    &:first-of-type {
      margin-top: 0px;
    }
  }
  ul {
    margin-top: 20px;
    margin: 15px;
  }
  li {
    font-weight: 600;
    margin: 8px 0px;
    list-style-type: decimal;
    &:last-of-type {
      margin-bottom: -5px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .header_left_stats {
    width: 40vw !important;
  }

  .select_filters {
    flex-direction: column;
    gap: 20px;
  }

  .header_logo {
    width: 150px !important;
  }

  .terms_cont {
    width: 100% !important;
  }
  .mobile_sm_padding {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .header__body {
    // justify-content: unset !important;
    // gap: 15px !important;
  }
  .header .header__right .wallet {
    margin-left: 5px !important;
  }
  #header_main {
    padding-top: 0px !important;
  }

  .popup {
    width: 90vw;
  }

  .password_full {
    width: 100% !important;
  }
  .header__right {
    width: 49% !important;
  }

  .search_form {
    position: unset !important;
  }

  .search_list {
    left: 0;
  }

  .filter_search_blog {
    flex-direction: column;
    gap: 10px;
  }

  .stats_crypto {
    flex-direction: column;
  }

  .mobile_flex {
    flex-direction: column !important;
  }
  .crypto__main {
    padding: 5px !important;
  }
  .title_mobile {
    font-size: 30px !important;
  }
  .desc_mobile {
    font-size: 20px !important;
  }
  .coins_link {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .mobile-button {
    margin-left: 10px !important;
  }
  .mobile_no_padding {
    padding: 0px !important;
  }
  .filter_search_blog > .input_body,
  .filter_search_blog > .search,
  .filter_search_blog > .search > div > div > input {
    width: 100% !important;
  }

  .details_grid {
    grid-template-columns: repeat(2, 50%);
    margin-top: 6vh;
    row-gap: 30px;
  }

  .details_grid > .detail {
    font-size: 14px !important;
  }

  .name_chain {
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 3px !important;
    margin-bottom: 3vh;
  }

  .details_grid > div {
    display: flex;
    flex-direction: column;
    padding-left: 0px !important;
    align-items: center;
  }

  .details_grid > div > div {
    font-size: 14px !important;
  }
  .coin_icon {
    width: 80px !important;
    height: 80px !important;
  }
}

.input_body {
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
}

.label {
  font-size: 15px;
  color: rgb(0, 0, 0);
  font-weight: 600;
}

.select {
  padding: 5px 5px;
  border: 1px solid rgb(0 0 0 / 8%);
  border-radius: 100px;
  background: transparent;
  outline: none;
  color: black;
  z-index: 999999999999;
  position: fixed;
}

.select > option {
  background: black;
  color: rgb(0, 0, 0);
  padding: 10px;
}

.css-6ipyj2-control {
  padding: 5px !important;
  border-radius: 100px !important;
}
/* 
.react-select__menu {
  background-color: #ffffff !important;
  background: #ffffff !important;
}

.react-select__menu-list {
  background-color: #ffffff !important;
  background: #ffffff !important;
}

.react-select__option {
  background-color: #ffffff !important;
  background: #ffffff !important;
} */
